var isMobile = {
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }
};


$(document).ready(function () {
  if ($('.ios_overlay').length > 0) {
    if (isMobile.iOS()) {
      $('.ios_overlay').css({'visibility': 'visible', 'opacity': '1'});
    }
    $('.ios_overlay').on('click', function (e) {
      e.preventDefault();
      let $this = $(this);
      if (navigator.onLine) {
        setIos(user, $this)
      } else {
        $this.css({'visibility': 'hidden', 'opacity': '0'});
      }
    })
  }
});

function setIos(assetId, overlay) {
  js_api.acquireLock({
    "asset_id": assetId,
    "dependants_only": 0,
    "force_acquire": true,
    "dataCallback": setAssetMetadata
  });

  function setAssetMetadata(object) {
    if (object[0]["warning"] || object["errorCode"] == "permissionError") {
      console.log('An error has occurred, maybe you are offline. please try again later.');
    } else {
      js_api.setMetadata({
        "asset_id": assetId,
        "field_id": 453599,
        "field_val": "no",
        "dataCallback": function () {
          releaseLock(assetId);
        }
      });
    }
  }

  function releaseLock(assetId) {
    js_api.releaseLock({
      "asset_id": assetId,
      "dataCallback": removeOverlay
    });
  }

  function removeOverlay() {
    overlay.css({'visibility': 'hidden', 'opacity': '0'});
  }
}