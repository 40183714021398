$(document).ready(function () {
  $('.articles_list--element .rmv_wrapper').on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    let actualArticle = $this.attr('data-articleid');
    let button = $this.parents('li');
    if (navigator.onLine) {
      removeButton(user, actualArticle, button);
    }
  })
});

function removeButton(assetId, articleId, button) {
  let actualArticlesString;
  js_api.acquireLock({
    "asset_id": assetId,
    "dependants_only":0,
    "force_acquire":true,
    "dataCallback": remArticleMetadata
  });
  function remArticleMetadata(object) {
    if (  object[0]["warning"] ||  object["errorCode"] == "permissionError") {
      console.log('An error has occurred, maybe you are offline. please try again later.');
    }  else {
      js_api.getMetadata({
        "asset_id": assetId,
        "dataCallback": storeToArrayToRemove
      });
    }
  }
  function storeToArrayToRemove(object) {
    $('.record_list').attr('data-articlelist', object["Saved.Record"]);
    actualArticles = object["Saved.Record"].split(';');
    if (actualArticles.indexOf(articleId) != -1) {
      for(var i in actualArticles){
        if(actualArticles[i]== articleId ){
          actualArticles.splice(i,1);
          break;
        }
      }
      actualArticlesString = actualArticles.join(';');
      js_api.setMetadata({
        "asset_id":assetId,
        "field_id":451003,
        "field_val": actualArticlesString ,
        "dataCallback":function() {
          releaseLockListArticle(assetId);
        }
      });
    }
  }
  function releaseLockListArticle(assetId) {
    js_api.releaseLock({
      "asset_id":assetId,
      "dataCallback":function() {
        setNonActive(button);
      }
    });
  }
  function setNonActive(button) {
    $('.record_list').attr('data-articlelist',actualArticlesString);
    button.remove();
  }
}