// This part in Matrix Start
//
// var user = 431889;
// let actualArticles = [];
// let toSaveArticles = [];
//
// This part in Matrix End

$(document).ready(function () {
  if ($('#referer_from_sw').length > 0 ) {
    $('#referer_from_sw').on('click', function(e){
      e.preventDefault();
      if(history.length > 1) {
        // console.log(history.length);
        window.history.go(-1);
      } else {
        // console.log(history.length + " back home");
        window.location.href = 'https://app.icas.com/home';
      }
    })
  }

  if ($('.main_article').length > 0 ) {
    let url = $('.main_article').attr('data-ajaxurl');
    if (navigator.onLine) {
      $.ajax({
        url: "https://app.icas.com/_designs/ajax-content/token" ,
        type: "GET",
      }).done(function (data, status) {
        // console.log('token');
        $('#token').attr('value',$(data).attr('value') );
        var options = new Array();
        options['key'] = '9400745924';
        js_api = new Squiz_Matrix_API(options);
        $.ajax({
          dataType: 'json',
          url: url,
          type: "GET",
        }).done(function (data, status) {
          // console.log("data received = " + data["userid"]);
          user = data["userid"];
          // console.log("onLoaduser: " + status);
          getArticles(user);
        }).fail(function (xhr, status, errorThrown) {
          $('.socialicons .button').addClass('buttonerror').html('Limited functionalities');
          console.log("Error: " + errorThrown);
          console.log("Status: " + status);
        });

      }).fail(function (xhr, status, errorThrown) {
        console.log('token error');
        console.log("Error: " + errorThrown);
        console.log("Status: " + status);
      });

    }

    $('.socialicons .button').on('click', function (e) {
      e.preventDefault();
      let $this = $(this);
      if (navigator.onLine) {
        if ($this.hasClass('active')) {
          let actualArticle = $this.attr('data-articleid');
          removeFromRecord(user, actualArticle, $this);
        } else {
          let actualArticle = $this.attr('data-articleid') + ';';
          addToRecord(user, actualArticle, $this);
        }
      }
    });

  }
});

// remove articleId from metadata
function removeFromRecord(assetId, articleId, button) {
  let actualArticlesString;
  js_api.acquireLock({
    "asset_id": assetId,
    "dependants_only": 0,
    "force_acquire": true,
    "dataCallback": remArticleMetadata
  });

  function remArticleMetadata(object) {
    if (object[0]["warning"] || object["errorCode"] == "permissionError") {
      console.log('An error has occurred, maybe you are offline. please try again later.');
    } else {
      js_api.getMetadata({
        "asset_id": assetId,
        "dataCallback": storeToArrayToRemove
      });
    }
  }

  function storeToArrayToRemove(object) {
    $('.main_article').attr('data-articlelist', object["Saved.Record"]);
    actualArticles = object["Saved.Record"].split(';');
    if (actualArticles.indexOf(articleId) != -1) {
      for (var i in actualArticles) {
        if (actualArticles[i] == articleId) {
          actualArticles.splice(i, 1);
          break;
        }
      }
      actualArticlesString = actualArticles.join(';');
      js_api.setMetadata({
        "asset_id": assetId,
        "field_id": 451003,
        "field_val": actualArticlesString,
        "dataCallback": function () {
          releaseLockListArticle(assetId);
        }
      });
    }
  }

  function releaseLockListArticle(assetId) {
    js_api.releaseLock({
      "asset_id": assetId,
      "dataCallback": function () {
        setNonActive(button);
      }
    });
  }

  function setNonActive(button) {
    $('.main_article').attr('data-articlelist', actualArticlesString);
    button.removeClass('active').html('Add to Learning Record');
  }
}


//add articleId to metadata
function addToRecord(assetId, articleId, button) {
  js_api.acquireLock({
    "asset_id": assetId,
    "dependants_only": 0,
    "force_acquire": true,
    "dataCallback": setArticleMetadata
  });

  function setArticleMetadata(object) {
    if (object[0]["warning"] || object["errorCode"] == "permissionError") {
      console.log('An error has occurred, maybe you are offline. please try again later.');
    } else {
      let alreadysaved = $('.main_article').attr('data-articlelist');
      toSaveArticles = alreadysaved + articleId;
      $('.main_article').attr('data-articlelist', toSaveArticles);
      js_api.setMetadata({
        "asset_id": assetId,
        "field_id": 451003,
        "field_val": toSaveArticles,
        "dataCallback": function () {
          releaseLockArticle(assetId);
        }
      });
    }
  }

  function releaseLockArticle(assetId) {
    js_api.releaseLock({
      "asset_id": assetId,
      "dataCallback": function () {
        setActive(button);
      }
    });
  }

  function setActive(button) {
    button.addClass('active').html('Added to Learning Record');
  }
}

//get metadata and apply active class accordingly
function getArticles(assetId) {
  js_api.getMetadata({
    "asset_id": assetId,
    "dataCallback": storeToArray
  });

  function storeToArray(object) {
    if (object["Saved.Record"]) {
      $('.main_article').attr('data-articlelist', object["Saved.Record"]);
      actualArticles = object["Saved.Record"].split(';');
      $('.socialicons .button').each(function () {
        let $this = $(this);
        let articleid = $this.attr('data-articleid').toString();
        if (actualArticles.indexOf(articleid) != -1) {
          $this.addClass('active').html('Added to Learning Record');
        }
      });
    }
  }
}

$(function() {


  var pwa = {
    'vars': {},
    'fn': {}
  };


  /*  Accordion
   *  ********************************  */

  // Initialisation
  $('.app_area .accordion').each(function(){
    var $accordion = $(this);
    var $content = $accordion.find('.content');
    $content.hide();
    $accordion.addClass('active');
  });
  // Interaction
  $('.app_area .accordion .handle').on('click', function(){
    var $handle = $(this);
    var $article = $handle.parent('article');
    var $content = $handle.next('.content');
    // Close clicked accordion
    if ($article.hasClass('open')) {
      pwa.fn.closeAccordion($article, $content);
    }
    // Open clicked accordion
    else {
      $content.slideDown(250, function(){
        $article.addClass('open');
      });
    }
    // Close other open accordions
    var $openArticles = $article.siblings('.open');
    $openArticles.each(function(){
      var $openArticle = $(this);
      var $openContent = $openArticle.children('.content');
      pwa.fn.closeAccordion($openArticle, $openContent);
    });
  });
  pwa.fn.closeAccordion = function($article, $content) {
    $article.removeClass('open');
    $content.slideUp(250);
  }


  window.pwa = pwa;


});