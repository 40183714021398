// this part is in Matrix start
// this part will be in the pain layout of the page
//
// var options = new Array();
// options['key'] = '9400745924';
// var js_api = new Squiz_Matrix_API(options);
// var user = 448785 ; //user ID
// var arrayCategories = [];
// var toAdd = [];
//
//
// this part is in Matrix end


$(document).ready(function () {
  if ($('.topics').length > 0) {

    //apply active class to subscribed categories
    if (navigator.onLine) {
      getCategories(user);
    }
    // add/remove category on Click
    $('ul.topics-list .topics-list__item').on('click', function (e) {
      e.preventDefault();
      let $this = $(this);
      $this.toggleClass('active');
    });

    //submit selections
    $('.nextButton a').on('click', function (e) {
      e.preventDefault();
      let $this = $(this);
      let destination = $this.attr('href');
      if (navigator.onLine) {
        $('ul.topics-list .topics-list__item').each(function () {
          if ($(this).hasClass('active')) {
            toAdd.push($(this).data('subscription'));
          }
        });
        setCategories(user, destination);
      } else {
        window.location.href = destination;
      }

    });
  }
});

//get metadata and apply active class accordingly
function getCategories(assetId) {
  js_api.getMetadata({
    "asset_id": assetId,
    "dataCallback": storeToArray
  });

  function storeToArray(object) {
    if (object["Selected.Topics.Nospace"]) {
      arrayCategories = object["Selected.Topics.Nospace"].split('; ');
      $('ul.topics-list .topics-list__item').each(function () {
        let $this = $(this);
        let category = $this.data('subscription').toString();
        if (arrayCategories.indexOf(category) != -1) {
          $this.addClass('active');
        }
      });
    }
  }
}

//acquire lock, save metadata, relase lock
function setCategories(assetId, urlDestination) {
  js_api.acquireLock({
    "asset_id": assetId,
    "dependants_only": 0,
    "force_acquire": true,
    "dataCallback": setAssetMetadata
  });

  function setAssetMetadata(object) {
    console.log('seta metadata. object:');
    console.log(object);
    if (/* object[0]["warning"] || */ object["errorCode"] == "permissionError") {
      console.log('An error has occurred, maybe you are offline. please try again later.');
    } else {
      var setStringCategories = toAdd.join('; ');
      js_api.setMetadata({
        "asset_id": assetId,
        "field_id": 455271,
        "field_val": setStringCategories,
        "dataCallback": function () {
          releaseLock(assetId);
        }
      });
    }
  }

  function releaseLock(assetId) {
    js_api.releaseLock({
      "asset_id": assetId,
      "dataCallback": redirect
    });
  }

  function redirect() {
    window.location.href = urlDestination;
  }
}